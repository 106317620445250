import React from "react";

const Word = ({ word, isFirstLoad }) => {
  return (
    <div
      className={`word ${isFirstLoad ? "in-bottom" : ""} ${
        word === "WORDLEER," ? "magic-word" : ""
      }`}
    >
      {word.split("").map((letter, ix) => (
        <span className="letter" key={ix}>
          {letter}
        </span>
      ))}
    </div>
  );
};

export default Word;
