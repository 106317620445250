import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from '@fortawesome/fontawesome-free-brands';

const Footer = ({ footerRef }) => {
  return (
    <footer ref={footerRef}>
       <div className="made-by" id="copyright">
        <a href="https://github.com/NickLindstrom/wordleer.com" rel="noreferrer" target="_blank">
          <FontAwesomeIcon icon={faGithub} />
        </a>
       <span>
          N © 2022 
         </span>
      </div>
      <div className="links">
        <a href="https://www.nytimes.com/games/wordle">Wordle</a>
        <a href="https://ordel.se/">Ordel</a>
        <a href="https://zaratustra.itch.io/dordle">Dordle</a>
        <a href="https://www.quordle.com/#/">Quordle</a>
      </div>
    </footer>
  );
};

export default Footer;
