import React from "react";

const Loading = () => {
  return (
    <div className="App loading">
      <header>
        <div className="logo">
          <h1 className="logo-text sr-only">Wordleer</h1>
          <img
            className="logo-image"
            src="/static/media/logo.7a878180eed061236fc1a5c2c11a4c5d.svg"
            alt="Wordleer"
          />
          <h2 className="logo-slogan">When you stuck</h2>
        </div>
        <div className="toggle-buttons">
          <button className="active" data-lang="sv">
            Swedish
          </button>
          <button className="" data-lang="en">
            English
          </button>
        </div>
      </header>
      <div className="wrapper">
        <div className="inputs ">
          <div className="inputs-header"></div>
          <div className="inputs-content">
            <span className="fake-label">Correct letters</span>
            <div className="positional"></div>
            <span className="fake-label">Incorrect positions</span>
            <div className="positional"></div>
            <label htmlFor="mustExclude">Letters to exclude</label>
            <input id="mustExclude" type="text" />
          </div>
        </div>

        <div className="result visible">
          <div className="word"></div>
          <div className="word"></div>
          <div className="word"></div>
          <div className="word"></div>
          <div className="word"></div>
          <div className="word"></div>
          <div className="word"></div>
          <div className="word"></div>
          <div className="word"></div>
        </div>
      </div>
      <footer>
        <div className="links">
          <a href="https://ordel.se/">Ordel</a>
          <a href="https://www.nytimes.com/games/wordle">Wordle</a>
          <a href="https://zaratustra.itch.io/dordle">Dordle</a>
          <a href="https://www.quordle.com/#/">Quordle</a>
        </div>
        <div className="made-by">N © 2022</div>
      </footer>
    </div>
  );
};

export default Loading;
