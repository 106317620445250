export const i18n = {
    en: {
      slogan: 'When you stuck',
      twistedSlogan: 'When you suck',
      mustInclude: 'Letters to include',
      mustExclude: 'Letters to exclude',
      correctLetters: 'Correct letters',
      inCorrectPositions: 'Incorrect positions',
      notValid: 'Not allowed character',
      numberOfWords: 'Words:',
      inputHeading: 'Inputs',
      welcomeMessage: 'Welcome to Wordleer, a Wordle help-page! With this site you can get help if you get stuck on a difficult word. Links to some of the games are located in the bottom of the page. Best of luck!'
      .toUpperCase()
      .split(" "),
      letterForPosition: 'Letter for position ',
    },
    sv: {
      slogan: 'När du fastnar',
      twistedSlogan: 'När du fasar',
      mustInclude: 'Bokstäver att inkludera',
      mustExclude: 'Bokstäver att utesluta',
      correctLetters: 'Korrekta bokstäver',
      inCorrectPositions: 'Felaktiga positioner',
      notValid: 'Felaktigt tecken inmatat',
      numberOfWords: 'Ord:',
      inputHeading: 'Inputfält',
      welcomeMessage: "Välkommen till Wordleer, en Ordel/Wordle hjälpsida! Här kan du få hjälp om du har fastnat på något svårt ord. Länkar finns längst ned på sidan till olika spel. Lycka till!"
      .toUpperCase()
      .split(" "),
      letterForPosition: 'Bokstav för position '
    }
  }