import { useEffect } from 'react';

export default function useGoogleAdSense(){
  useEffect(() => {
    const head = document.querySelector("head");
    const scriptElm = document.createElement("script");

    scriptElm.setAttribute('async','');
    scriptElm.id = 'adsenseScriptElm'
    scriptElm.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6339890551805935';
    scriptElm.crossOrigin = 'anonymous';
    head.appendChild(scriptElm);

    return () => {
      head.removeChild(scriptElm);
    };
  }, []);
};