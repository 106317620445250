import React from "react";
import { i18n } from "../hooks/Translations";

const Input = ({ id, lang, onChange, handleFocusElement, forwardRef }) => {
  return (
    <>
      <label htmlFor={id}>{i18n[lang][id]}</label>
      <input
        id={id}
        type="text"
        ref={forwardRef}
        onChange={onChange}
        onFocus={handleFocusElement}
      ></input>
    </>
  );
};

export default Input;
