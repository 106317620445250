import React from "react";
import { i18n } from "../hooks/Translations";
import logo from "../assets/logo.svg";

const Header = ({ lang, setLang }) => {
  const handleToggleLang = (e) => {
    const newLang = e.target.dataset.lang;
    setLang(newLang);
  };

  return (
    <header>
      <div className="logo">
        <h1 className="logo-text sr-only">Wordleer</h1>
        <img className="logo-image" src={logo} alt="Wordleer"></img>
        <h2 className="logo-slogan">{i18n[lang].slogan}</h2>
      </div>
      <div className="toggle-buttons">
        <button
          className={lang === "sv" ? "active" : ""}
          onClick={handleToggleLang}
          data-lang="sv"
        >
          Swedish
        </button>
        <button
          className={lang === "en" ? "active" : ""}
          onClick={handleToggleLang}
          data-lang="en"
        >
          English
        </button>
      </div>
    </header>
  );
};

export default Header;
