/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { englishWords, swedishWords } from "./hooks/WordList";
import { i18n } from "./hooks/Translations";
import useGoogleAdSense from './hooks/useGoogleAdSense';
import Loading from "./components/Loading";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Input from "./components/Input";
import Word from "./components/Word";
import LetterPositioning from "./components/LetterPositioning";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

const loadingTime = 1000;
const speedOfFlyingWords = 100;
const timeForShareButton = 4500;
const notAllowedChars = /^[a-zA-ZåäöÅÄÖ]*$/;

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [inCorrectPosition, setIncorrectPosition] = useState([
    [],
    [],
    [],
    [],
    [],
  ]);
  const [lettersToInclude, setLettersToInclude] = useState([]);
  const [lettersToExclude, setLettersToExclude] = useState([]);
  const [hideInputFields, setHideInputFields] = useState(false);
  const [correctLetters, setCorrectLetters] = useState(["", "", "", "", ""]);
  const [lang, setLang] = useState("sv");
  const [possibleAnswers, setPossibleAnswers] = useState([]);
  const [wordlist, setWordlist] = useState(swedishWords);
  const [errorMessage, setErrorMessage] = useState("");
  const appRef = useRef();
  const correctLettersRef = useRef();
  const inCorrectPositionLettersRef = useRef();
  const resultRef = useRef();
  const inputsRef = useRef();
  const footerRef = useRef();
  const excludeInputRef = useRef();

  useGoogleAdSense()

  function shuffle(array) {
    let currentIndex = array.length;
    let randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const getFiltredPossibleAnswers = () => {
    return wordlist.filter(
      (word) =>
        lettersToInclude.every((letter) => word.indexOf(letter) !== -1) &&
        lettersToExclude.every((letter) => word.indexOf(letter) === -1) &&
        (word[0] === correctLetters[0] || correctLetters[0] === "") &&
        (word[1] === correctLetters[1] || correctLetters[1] === "") &&
        (word[2] === correctLetters[2] || correctLetters[2] === "") &&
        (word[3] === correctLetters[3] || correctLetters[3] === "") &&
        (word[4] === correctLetters[4] || correctLetters[4] === "") &&
        !inCorrectPosition[0].includes(word[0]) &&
        !inCorrectPosition[1].includes(word[1]) &&
        !inCorrectPosition[2].includes(word[2]) &&
        !inCorrectPosition[3].includes(word[3]) &&
        !inCorrectPosition[4].includes(word[4])
    );
  };

  useEffect(() => {
    if (window.location.href.includes(".se")) setLang("sv");
    else if (window.location.href.includes(".com")) setLang("en");
    else setLang("en");
    $("#copyright").on("mouseenter", () =>
      $(".logo-slogan").text(i18n[lang].twistedSlogan)
    );
    $("#copyright").on("mouseleave", () =>
      $(".logo-slogan").text(i18n[lang].slogan)
    );
    setTimeout(() => {
      setIsLoading(false);
    }, loadingTime);
    setTimeout(() => {
      $(appRef.current).removeClass("invisible");
      let allWords = $(".result .word.in-bottom");
      let counter = 0;
      const wordInter = setInterval(() => {
        $(allWords[counter]).removeClass("in-bottom");
        if (!allWords[counter]) clearInterval(wordInter);
        counter++;
      }, speedOfFlyingWords);
    }, loadingTime + 200);
    setTimeout(() => {
      $("#at-expanding-share-button").slideDown();
    }, timeForShareButton);
  }, []);

  useEffect(() => {
    if (!resultRef.current) return;
    if (errorMessage) return;
    resultRef.current.classList.remove("visible");
    resultRef.current.classList.remove("almost-full-height");
    if (
      inCorrectPosition.every((e) => e.length === 0) &&
      lettersToInclude.length === 0 &&
      lettersToExclude.length === 0 &&
      correctLetters.every((letter) => letter === "")
    ) {
      if (
        possibleAnswers.length > 0 &&
        possibleAnswers.length !== i18n["sv"].welcomeMessage.length &&
        possibleAnswers.length !== i18n["en"].welcomeMessage.length
      ) {
        setPossibleAnswers([...shuffle(wordlist)]);
      } else {
        if (!lang) return;
        setPossibleAnswers([...i18n[lang].welcomeMessage]);
        resultRef.current.classList.add("almost-full-height");
        return;
      }
      return;
    }
    setTimeout(() => {
      setIsFirstLoad(false);
      setPossibleAnswers(getFiltredPossibleAnswers());
    }, 100);
  }, [
    lettersToInclude,
    lettersToExclude,
    inCorrectPosition,
    correctLetters,
    wordlist,
  ]);

  useEffect(() => {
    if (lang === "sv") setWordlist(swedishWords);
    else setWordlist(englishWords);
    let isStartMessage = Object.values(i18n).some((language) => {
      return (
        language.welcomeMessage.length === possibleAnswers.length ||
        possibleAnswers.length === 0
      );
    });
    if (isStartMessage && !errorMessage) {
      setTimeout(() => {
        resultRef.current.classList.add("almost-full-height");
        setPossibleAnswers(i18n[lang].welcomeMessage);
      }, loadingTime);
    }
  }, [lang]);

  useEffect(() => {
    if (!resultRef.current) return;
    $(resultRef.current).addClass("visible");
  }, [possibleAnswers]);

  const handleChange = (e) => {
    if (e.target.id === "mustInclude") handleChangeInclude(e);
    if (e.target.id === "mustExclude") handleChangeExclude(e);
  };

  const handleChangeInclude = (e) => {
    setLettersToInclude(e.target.value.toUpperCase().split(""));
  };

  const handleChangeExclude = (e) => {
    setLettersToExclude(e.target.value.toUpperCase().split(""));
  };

  const handleKeyPress = (e) => {
    $(e.target).removeClass();
    $(e.target).addClass("number-of-letters--" + e.target.value.length);
    if (!e.target.value.match(notAllowedChars)) {
      $(e.target).addClass("no-valid");
      setErrorMessage("notValid");
      setPossibleAnswers([]);
      let allInputs = [
        ...correctLettersRef.current.children,
        ...inCorrectPositionLettersRef.current.children,
      ];
      allInputs.forEach((elm) => {
        if (elm !== e.target) elm.disabled = true;
      });
      excludeInputRef.current.disabled = true;
      return;
    } else {
      $(e.target).removeClass("no-valid");
      setErrorMessage("");
      let allInputs = [
        ...correctLettersRef.current.children,
        ...inCorrectPositionLettersRef.current.children,
      ];
      allInputs.forEach((elm) => {
        elm.disabled = false;
      });
      excludeInputRef.current.disabled = false;
    }
    if (e.target.id.includes("incorrect")) {
      let inCorrectPositionLetters = [
        ...inCorrectPositionLettersRef.current.children,
      ].map((elm) => elm.value.toUpperCase().split(""));
      setIncorrectPosition(inCorrectPositionLetters);
      setLettersToInclude(inCorrectPositionLetters.flat(1));
    } else if (e.target.id.includes("correct")) {
      $(e.target).toggleClass("has-value", e.target.value.length > 0);
      if (e.target.value === " ") return;
      let correctPositionLetters = [...correctLettersRef.current.children].map(
        (elm) => elm.value.toUpperCase()
      );
      setCorrectLetters(correctPositionLetters);
    }
    let next = e.target.nextElementSibling;
    let prev = e.target.previousElementSibling;
    if (e.keyCode === 8 && !e.target.value && prev) {
      // 8 = 'Backspace'
      prev.focus();
    } else if (e.keyCode === 9 || e.keyCode === 16) {
      // 16 = 'Shift'  9 = 'Tab'
      return;
    } else {
      if (e.target.value.length > 0) {
        if (next && next.value.length === 0) next.focus();
      }
    }
  };
  const showPossibleNumberOfWords = () => {
    return (
      possibleAnswers.length !== i18n[lang].welcomeMessage.length &&
      possibleAnswers.length !== wordlist.length &&
      errorMessage.length === 0
    );
  };

  const handleFocusElement = () => {
    $("html,body").animate(
      { scrollTop: $(inputsRef.current).offset().top - 25 },
      400
    );
  };

  const handleToggleShowInputField = () => {
    setHideInputFields(!hideInputFields);
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="App invisible" ref={appRef}>
      <Header lang={lang} setLang={setLang} />
      <div className="wrapper">
        {errorMessage && (
          <span className="fake-label error-info">
            {i18n[lang][errorMessage]}
          </span>
        )}
        <div
          className={`inputs ${hideInputFields ? "invisible" : ""}`}
          ref={inputsRef}
        >
          <div className="inputs-header">
            <button
              className="toggle-input-fields"
              onClick={handleToggleShowInputField}
            >
              <FontAwesomeIcon icon={faAngleUp} />
              <span>{i18n[lang].inputHeading}</span>
            </button>
          </div>
          <div className="inputs-content">
            <span className="fake-label">{i18n[lang].correctLetters}</span>
            <LetterPositioning
              className="positional correct"
              forwardRef={correctLettersRef}
              handleKeyPress={handleKeyPress}
              handleFocusElement={handleFocusElement}
              lang={lang}
              maxLength="1"
            />
            <span className="fake-label">{i18n[lang].inCorrectPositions}</span>
            <LetterPositioning
              className="positional incorrect"
              forwardRef={inCorrectPositionLettersRef}
              handleKeyPress={handleKeyPress}
              handleFocusElement={handleFocusElement}
              lang={lang}
              maxLength="4"
            />
            <Input
              id="mustExclude"
              forwardRef={excludeInputRef}
              onChange={handleChange}
              handleFocusElement={handleFocusElement}
              lang={lang}
            />
            {showPossibleNumberOfWords() && (
              <span className="fake-label result-info">
                {i18n[lang].numberOfWords} {possibleAnswers.length}
              </span>
            )}
          </div>
        </div>
        <div className={`result`} ref={resultRef}>
          {possibleAnswers.slice(0, 100).map((word, i) => {
            return <Word word={word} isFirstLoad={isFirstLoad} key={i} />;
          })}
        </div>
      </div>
      <Footer footerRef={footerRef} />
    </div>
  );
};

export default App;
