import React from "react";
import { i18n } from "../hooks/Translations";

const LetterPositioning = ({
  className,
  forwardRef,
  handleKeyPress,
  handleFocusElement,
  lang,
  maxLength
}) => {

  return (
    <div className={className} ref={forwardRef}>
      {[...Array(5)].map((num, i) => {
        return (
          <input
            id={`inputLetters${className.split(" ")[1]}${i}`}
            key={i}
            type="text"
            maxLength={maxLength}
            onKeyUp={handleKeyPress}
            onFocus={handleFocusElement}
            aria-label={`${i18n[lang].letterForPosition} ${i + 1}`}
          ></input>
        );
      })}
    </div>
  );
};

export default LetterPositioning;
